/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {

    // DOM ready
    $(function () {

        var App = App || {};
        var Views = {};
        var stickyTimeout;

        Views['0'] = Backbone.View.extend({

            initialize: function() {
                if ($(window).width() <= 768) {

                } else {
                    clearTimeout(stickyTimeout);
                    $('main .side-nav, main .map').hide();
                }
            }
        });

        Views['1'] = Backbone.View.extend({

            initialize: function() {
                this.makeSticky();
                this.moveIndicator();
            },

            makeSticky: function() {
                if ($('.disclaimer.ready').length) {
                    stickyTimeout = setTimeout(function() {
                        $('main .side-nav, main .map').fadeIn();
                    }, 300);
                }
            },

            moveIndicator: function() {
                $('main .side-nav #indicator').attr("transform", "translate(22,65)");
            }
        });

        Views['2'] = Backbone.View.extend({

            initialize: function() {
                this.moveIndicator();
            },

            moveIndicator: function() {
                $('main .side-nav #indicator').attr("transform", "translate(8.5,152)");
            }
        });

        Views['3'] = Backbone.View.extend({

            initialize: function() {
                this.moveIndicator();
            },

            moveIndicator: function() {
                $('main .side-nav #indicator').attr("transform", "translate(32,262)");
            }
        });

        Views['4'] = Backbone.View.extend({

            initialize: function() {
                this.moveIndicator();
            },

            moveIndicator: function() {
                $('main .side-nav #indicator').attr("transform", "translate(2,365)");
            }
        });

        Views['5'] = Backbone.View.extend({

            initialize: function() {
                this.moveIndicator();
            },

            moveIndicator: function() {
                $('main .side-nav #indicator').attr("transform", "translate(-8,440)");
            }
        });

        Views['6'] = Backbone.View.extend({

            initialize: function() {
                this.moveIndicator();
            },

            moveIndicator: function() {
                $('main .side-nav #indicator').attr("transform", "translate(16,522)");
            }
        });

        App.Router = Backbone.Router.extend({

            routes: {
                'playground/beaujolais/': 'home',
                'playground/beaujolais/*slug': 'goTo',
                '': 'home',
                '#': 'home',
                '*slug': 'goTo'
            },

            initialize: function() {

                //Backbone.history.start({
                //    pushState: true
                //});

                var self = this;
                var stickyTimeout;

                $('.map, .side-nav').hide();

                if ($(window).width() <= 768) {
                    $('footer.content-info').hide();
                }

                var hideMobileNav = function() {
                    if ($(window).width() <= 768) {
                        $('.side-nav').addClass('mobile-hide');
                    }
                };

                var hideMobileNavButton = function() {
                    if ($(window).width() <= 768) {
                        $('.mobile-nav').fadeOut('fast');
                    }
                };

                var showMobileNavButton = function() {
                    if ($(window).width() <= 768) {
                        $('.mobile-nav').fadeIn();
                    }
                };

                $('.fullpage').fullpage({
                    sectionSelector: 'section',
                    anchors: ['main', 'eau', 'terre', 'feu', 'mer', 'vin', 'sel'],
                    onLeave: function(index, nextIndex, direction) {
                        self.goTo(nextIndex - 1);
                        if (nextIndex === 1) {
                            clearTimeout(stickyTimeout);
                            $('main .side-nav, main .map, .mobile-nav').fadeOut('fast');
                            if ($(window).width() <= 768) {
                                $('footer.content-info').fadeOut('fast');
                            }
                        } else {
                            if ($(window).width() <= 768) {
                                $('footer.content-info, .mobile-nav').fadeIn('fast');
                            }
                        }
                    },
                    afterLoad: function (anchorLink, index) {

                        if (index === 1) {
                            $('.mobile-nav').hide();
                        }

                        if (index > 1) {

                            if ($('.disclaimer.ready').length) {
                                if ($(window).width() <= 768) {

                                } else {
                                    stickyTimeout = setTimeout(function () {
                                        $('main .side-nav, main .map').fadeIn();
                                    }, 300);

                                }
                            }
                        }
                        //var slug = $(this).data("slug");
                        //Backbone.history.navigate("#" + slug + "/", {trigger: true});
                    },
                    afterRender: function() {

                        // Emit event that fullpage is loaded
                        $('body').trigger('fullpageReady');

                        // Disable slide change before disclaimer is accepted
                        $.fn.fullpage.setAllowScrolling(false);
                        $.fn.fullpage.setKeyboardScrolling(false);

                        $('.side-nav .logo').click(function() {
                            $.fn.fullpage.moveTo(1);
                            hideMobileNav();
                        });


                    }
                });

                // Init youtube videos
                var players = plyr.setup('.youtube-player', {
                    clickToPlay: false,
                    controls: []
                });

                $.each(players, function(i, e) {
                    e.on('ready', function(event) {
                        var instance = event.detail.plyr;
                        instance.play();
                        instance.pause();
                    });
                });

                var getPlayer = function() {
                    var $activePlayer = $('section .player.show');
                    var $activeSection = $activePlayer.closest('section');
                    var players = plyr.get('section[data-view="' + $activeSection.data('view') + '"] .player');
                    return players[0];
                };

                var showPlayer = function(section) {
                    var $section = $(section).closest('section');
                    $.fn.fullpage.setAllowScrolling(false);
                    $.fn.fullpage.setKeyboardScrolling(false);
                    $('.map, .side-nav').hide();
                    $('.controls').removeClass('invisible');
                    $('.mobile-nav').fadeOut('fast');
                    $section.find('.player').addClass('show');
                    var player = getPlayer();
                    player.play();
                };

                var closePlayer = function() {

                    var toArticle = $('article.show').length,
                        player = getPlayer(),
                        $activeSection = $('section .player.show').closest('section');

                    $('.mobile-nav').fadeIn('fast');

                    if (toArticle) {
                        $('.controls').addClass('invisible');
                        player.stop();
                        $activeSection.find('.player').removeClass('show');
                    } else {
                        $.fn.fullpage.setAllowScrolling(true);
                        $.fn.fullpage.setKeyboardScrolling(true);
                        $('.map, .side-nav').fadeIn();
                        $('.controls').addClass('invisible');
                        player.stop();
                        $activeSection.find('.player').removeClass('show');
                    }

                    $('.controls-button.pause, .controls-button.sound').removeClass('active');
                    if (player.isMuted()) {
                        player.toggleMute();
                    }
                };

                $('.show-player').click(function(e) {
                    e.preventDefault();
                    showPlayer(this);
                });

                $('.controls .pause').click(function() {
                    var player = getPlayer();
                    player.togglePlay();
                    $(this).toggleClass('active');
                });

                $('.controls .close').click(function() {
                    closePlayer();
                });

                $('.controls .sound').click(function() {
                    var player = getPlayer();
                    player.toggleMute();
                    $(this).toggleClass('active');
                });

                $('.controls .fullscreen').click(function() {
                    var player = getPlayer();
                    player.toggleFullscreen();
                });

                var closeArticle = function() {
                    $('article.show').removeClass('show');
                    $('.map').fadeIn();
                    $('footer.content-info').fadeIn();
                    $.fn.fullpage.setAllowScrolling(true);
                    $.fn.fullpage.setKeyboardScrolling(true);
                    $('.side-nav').fadeIn();
                    showMobileNavButton();
                };

                $(document).keyup(function(e) {
                    var $activePlayer = $('section .player.show'),
                        $activeArticle = $('section article.show');
                    if (e.keyCode === 27 && $activePlayer.length) {
                        closePlayer();
                    } else if (e.keyCode === 27 && $activeArticle.length) {
                        closeArticle();
                    }
                });

                // Mobile map
                $('.mobile-nav').click(function() {
                    $('.side-nav').fadeIn().removeClass('mobile-hide');
                });

                $('.mobile-close').click(function() {
                    $('.side-nav').addClass('mobile-hide');
                });

                // Map
                $('.map').click(function(e) {
                    e.preventDefault();
                    $.fn.fullpage.setAllowScrolling(false);
                    $.fn.fullpage.setKeyboardScrolling(false);
                    $('.map').hide();
                    $('footer.content-info').hide();
                    $('.side-nav').hide();
                    $('.map-overlay').addClass('show');

                    if ($(window).width() <= 768) {
                        $(".map-overlay .selector svg .title.active").removeClass('active');
                    }
                });

                $('.map-overlay .close').click(function() {
                    $('.map-overlay.show').removeClass('show');
                    $('.map').fadeIn();
                    $('footer.content-info').fadeIn();
                    $.fn.fullpage.setAllowScrolling(true);
                    $.fn.fullpage.setKeyboardScrolling(true);
                    $('.side-nav').fadeIn();

                    if ($(window).width() <= 768) {
                        $(".map-overlay .content").hide().removeClass('active');
                        $(".map-overlay").removeClass('inner');
                    }
                });

                $('.map-overlay .slider').slick({
                    dots: true,
                    speed: 500,
                    infinite: false,
                    arrows: false,
                    responsive: [{
                        breakpoint: 576,
                        settings: "unslick"
                    }]
                });

                $('.map-overlay .back').click(function() {
                    $(".map-overlay .selector svg .title").removeClass('active');
                    $('.map-overlay .region').removeClass('active');
                    $(".map-overlay").removeClass('inner');
                    $(".map-overlay .content").fadeOut().removeClass('active');
                });

                $(".map-overlay .selector svg .title").click(function() {
                    var slideIndex = $(this).attr('mydata:region');
                    $(".map-overlay .selector svg .title").removeClass('active');
                    $(this).addClass('active');
                    $('.map-overlay .region').removeClass('active');
                    $('.map-overlay .region[data-region="'+slideIndex+'"]').delay(600).addClass('active');

                    if ($(window).width() <= 768) {
                        $(".map-overlay").addClass('inner');
                        $(".map-overlay .content").fadeIn().addClass('active');
                    }
                });

                // Article
                $('.show-article').click(function(e) {
                    e.preventDefault();
                    var target = $(this).data('article');

                    if ($(this).hasClass('from-video')) {
                        closePlayer();
                    }

                    hideMobileNavButton();

                    $.fn.fullpage.setAllowScrolling(false);
                    $.fn.fullpage.setKeyboardScrolling(false);
                    $('.map').hide();
                    $('footer.content-info').hide();
                    $('.side-nav').hide();
                    $(this).closest('section').find('article#' + target).addClass('show');
                });

                $('article .close').click(function() {
                    closeArticle();
                });

                $('article .article-content .slider').slick({
                    dots: true,
                    speed: 500,
                    infinite: false,
                    arrows: true,
                    prevArrow: '<button type="button" class="slick-prev">Précédent</button>',
                    nextArrow: '<button type="button" class="slick-next">Suivant</button>',
                    responsive: [{
                        breakpoint: 576,
                        settings: "unslick"
                    }]
                });

                // To prevent flash upon page loading
                $('article').removeClass('invisible');

                $(".side-nav .navigation svg .link").click(function() {
                    var slideIndex = $(this).attr('mydata:slide');
                    $.fn.fullpage.moveTo(slideIndex);
                    hideMobileNav();
                });

                $('.deeper, .arrow').click(function () {
                    setTimeout(function() {
                        $.fn.fullpage.moveSectionDown();
                    }, 350);
                });

                window.Pace.on("done", function () {
                    setTimeout(function () {
                        $('body > .mask .loader').fadeOut().queue(function() {
                            $('body > .mask .disclaimer').addClass('ready');
                        });
                        $('body > .mask .disclaimer .btn').click(function(e) {
                            e.preventDefault();
                            setTimeout(function() {
                                $('body > .mask').addClass('ready').delay(1150).fadeOut();
                                $.fn.fullpage.setAllowScrolling(true);
                                $.fn.fullpage.setKeyboardScrolling(true);
                                if ($('section.active').data('view') > 0) {
                                    stickyTimeout = setTimeout(function () {
                                        $('main .side-nav, main .map').fadeIn();
                                    }, 300);
                                }
                            }, 350);

                        });
                    }, 1000);
                });

                $('.fb-share').click(function(e) {
                    e.preventDefault();
                    window.open($(this).attr('href'), 'fbShareWindow', 'height=285, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                    return false;
                });
            },

            //navigate: function(url) {
            //    this.navigate(url, {trigger: true});
            //},

            home: function() {
                if ($(window).width() <= 768) {
                    $('footer.content-info, .mobile-nav').hide();
                } else {
                    $('.map, .side-nav').hide();
                }
                //this.goTo('0');
            },

            /*scrollTo: function(slideIndex) {

                var data = ["eau"];
                var match = data.indexOf(slideIndex);

                $('body').on("fullpageReady", function() {

                    if (match !== -1) {
                        this.goTo(match + 1);
                    } else if (parseInt(slideIndex)) {
                        this.goTo(slideIndex);
                    } else {
                        this.goTo(0);
                    }
                });
            },*/

            goTo: function(viewIndex) {

                if (Views[viewIndex] !== void 0) {
                    new Views[viewIndex]({
                        el: $('.fullpage section[data-view='+ viewIndex +']').get(0)
                    });
                }
            }
        });

        var app = new App.Router();

    });

})(jQuery);

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                window.circle = new ProgressBar.Circle('#progress', {
                    color: '#d11944',
                    duration: 200,
                    easing: 'easeInOut',
                    strokeWidth: 3
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
